import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { StaticImage } from '../../utils/image';
import SubMenuList from './SubMenuList';
import SearchIcon from '@material-ui/icons/Search';
import { Box, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import BarDuoTone from '../iconComponent/BarDuoTone';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from 'react-device-detect';
import MobileDeviceIndex from './MobileDeviceIndex';
import BrowserDeviceIndex from './BrowserDeviceIndex';
import { checkUser, checkUserBool } from '../../utils/checkUser';
import HomeIcon from '../iconComponent/bottomNavigator/Home';
import { useWindowSize } from '../../utils/WindowSize';
import { getLocalStorage, setLocalStorage } from '../../utils/localStorage';
import AlertInfo from '../../utils/AlertInfo';
import Swal from 'sweetalert2';
import ApiContent from '../../api/content';
import ApiUser from '../../api/user';

import { Image, Modal, Button } from 'antd';
import { v4 } from 'uuid';
import ApiNotification from '../../api/notification';
import { ArrowUpward } from '@material-ui/icons';
const drawerWidth = 300;

const menuState = [
  {
    label: 'ระบบกลางทางกฎหมาย',
    icon: StaticImage('/images/icon/drawer/info-duotone-copy.png'),
    type: 'more',
  },
  // {
  //   label: 'เกี่ยวกับการทดสอบระบบ',
  //   icon: StaticImage('/images/icon/drawer/info-duotone-copy.png'),
  //   type: 'test',
  // },
  {
    label: 'ติดต่อเรา',
    icon: StaticImage('/images/icon/drawer/landmark-duotone-2.png'),
    type: 'contactus',
  },
  {
    label: 'Privacy Policy',
    icon: StaticImage('/images/icon/drawer/user-shield-duotone.png'),
    type: 'privacypolicy',
  },
  {
    label: 'Term & Condition',
    icon: StaticImage('/images/icon/drawer/file-medical-alt-duotone.png'),
    type: 'termcondition',
  },
  {
    label: 'Security Policy',
    icon: StaticImage('/images/icon/drawer/shield-alt-duotone.png'),
    type: 'securitypolicy',
  },
  // {
  //   label: 'ระเบียบเว็บบอร์ด',
  //   icon: StaticImage('/images/icon/drawer/shield-alt-duotone.png'),
  //   type: 'rule',
  // },
];

const useDrawerStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    borderRadius: '30px',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'center',
    height: 158,
    backgroundColor: '#ebf0f8',
    // padding: '19px 17px 25px 24px',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    borderRadius: '50px',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    borderRadius: '50px',
  },
}));

const Main = (props) => {
  const [visible, setVisible] = React.useState(true);
    const [visible2, setVisible2] = React.useState(false);
	const [width, height] = useWindowSize();
  const userState = useSelector((state) => state.user);
  const history = useHistory();
  const [pathname, setPathname] = useState(window.location.pathname);
  const classes = useStyles();
  const dispatch = useDispatch();
  const classesDrawer = useStyles();
  const [isModalConsent, setisModalConsent] = React.useState(false);
  const [isModalImage, setisModalImage] = React.useState(false);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [content1, setcontent1] = React.useState({});
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  useEffect(() => {
    if (visible == false) {
      //setisModalImage(true);
    }
  }, [visible]);

  React.useEffect(() => {
    getUUID();
    if (checkUserBool(userState)) {
      getMe();
    }

    return () => { };
  }, []);

  useEffect(() => {
    if (document.getElementById("tailwind-js")) {

    } else {
      const script = document.createElement("script");
      script.src = `${StaticImage("/tailwind.js")}`;
      script.id = "tailwind-js";
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  useEffect(() => {
    setPathname(window.location.pathname);
  }, [pathname]);

  const checkConsent = async () => {
    const consent = await getLocalStorage('consent');
    if (consent == 1) {
      return false;
    } else {
      await getContent();
      setisModalConsent(true);
    }
  }

  const getUUID = async () => {
    let uuid = await getLocalStorage('uuid');
    if (uuid) {
      return false;
    } else {
      setLocalStorage('uuid', v4())
    }
  }



  const getContent = async () => {
    try {
      Swal.showLoading();
      const termcondition = await ApiContent.get({ type: 'termcondition' });

      if (termcondition.status == 200) {
        Swal.close();
        const { data } = termcondition.data;
        setcontent1(data);
      }
    } catch (error) {
      Swal.fire('เกิดข้อผิดพลาด', error, 'warning').then((result) => {
        history.go(-1);
      });
    }
  };

  const getMe = async () => {
    try {
      const user = await ApiUser.me();
      if (user.status == 200) {
        dispatch({ type: 'set_user', user: user.data });
      }
    } catch (error) {

    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role='presentation'
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className={classes.drawerHeader}>
        <img
          onClick={handleDrawerClose}
          style={{ position: 'absolute', right: 25, top: 10 }}
          src={StaticImage('/images/icon/drawer-header-close.png')}
        />
        <img
          onClick={handleDrawerClose}
          height={70}
          // src={StaticImage('/images/logo/cld-logo.png')}
          src={StaticImage('/images/logo/cld-beta-version-1.svg')}
        />
        {/* <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton> */}
      </div>
      <Divider />
      <List>
        {menuState.map((text, index) => (
          <ListItem onClick={() => navigate(text.type)} button key={text.label}>
            <ListItemIcon>
              <div
                style={{
                  width: '46px',
                  height: '46px',
                  borderRadius: 23,
                  backgroundColor: '#b6bdca',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: 15,
                }}
              >
                <img src={text.icon} />
              </div>
            </ListItemIcon>
            <ListItemText primary={text.label} />
          </ListItem>
        ))}
      </List>
      <Divider />
    </div>
  );

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const navigate = (type) => {
    if (type == 'more') {
      history.push(`/MeanLaw?type=${type}`);
    }
    if (type == 'test') {
      history.push(`/Test?type=${type}`);
    } else {
      history.push(`/WebView?type=${type}`);
    }
  };

  const handleCancel = async () => {
    await setLocalStorage('consent', '1')
    setisModalConsent(false);
  };

  return (
    <div>
      {visible ?
        <div style={{
          height: '100vh',
          width: '100vw',
          position: 'fixed',
          top: 0,
          left: 0,
          backgroundImage: `url(${StaticImage('/images/BG.png')})`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
	  backgroundRepeat: 'no-repeat',
	  backgroundSize: 'cover'
        }}>
          {/* <div class="photo">
            <img src="https://www.dga.or.th/wp-content/uploads/2022/05/Photo-Frame.png" />
          </div>
          <div className='d-flex flex-column'>
            <img src={`${StaticImage('/images/BG4.png')}`} />
            <div class="link">
              <a href="https://www.dga.or.th/home/">
                <img src="https://www.dga.or.th/wp-content/uploads/2022/05/Button.png" />
              </a>
            </div>
          </div> */}
          <div class="landing-block">
            <div class="photo">
              <img width={isMobile ? 335 : 430} src={`${StaticImage('/images/Photo.png')}`} />
            </div>
            <div class="landing-container">
              <div class="text"><img width={isMobile ? 335 : 430} src={`${StaticImage('/images/Text.png')}`} /></div>
              <div class="link cursor-pointer flex items-center justify-center" onClick={() => setVisible(false)}>
                <img src={`${StaticImage('/images/Button.png')}`} />
              </div>
            </div>
          </div>
        </div>
        :
        <>
          <Modal
            title={
              'นโยบายเว็บไซต์'
            }
            closable={false}
            style={{ zIndex: 9999 }}
            maskClosable={false}
            cancelText={'ตกลง'}
            okButtonProps={{ hidden: true }}
            visible={isModalConsent}
            // onOk={handleOk}
            // onCancel={handleCancel}
            onCancel={handleCancel}
          >
            <span
              className='contact-description ql-editor'
              dangerouslySetInnerHTML={{ __html: content1?.content }}
            ></span>
          </Modal>
          <Modal
            title={
              'ประกาศแจ้งเตือน'
            }
            closable={false}
            style={{ zIndex: 9999, width: 'auto' }}
            maskClosable={false}
            cancelText={'ปิด'}
            width={450}
            okButtonProps={{ hidden: true }}
            visible={isModalImage}
            // onOk={handleOk}
            // onCancel={handleCancel}
            onCancel={() => {
              setisModalImage(false)
            }}
          >
            <div >
              <Image preview={true} height={isMobile ? 350 : 400} src={StaticImage("/images/เตือนภัย-LINE-DGA-Thailand-ปลอม.png")} />
            </div>
          </Modal>


          {width >= 768 && <BrowserDeviceIndex {...props} />}
          {width < 768 && <MobileDeviceIndex />}

          {/* {width < 768 && (
            <div className='bottom-navigation'>
              <div
                onClick={() => history.push('/Phase2')}
                className={`d-flex-center flex-column ${pathname.includes("Phase2") ? ' active' : ''}`}
              >
                <img
                  src={StaticImage(
                    '/images/bottomnavigation/home-lg-alt-duotone-gray.svg'
                  )}
                  alt='home'
                  className={pathname == '/' ? 'active' : ''}
                />
                <label>หน้าหลัก</label>
              </div>
              <div
                onClick={() => history.push('/')}
                className={`d-flex-center flex-column ${pathname == "" ? ' active' : ''}`}
              >
                <img
                  src={StaticImage(
                    '/images/bottomnavigation/comment-alt-edit-duotone-gray.svg'
                  )}
                  alt='home'
                  className={pathname == '/' ? 'active' : ''}
                />
                <label>โครงการรับฟัง</label>
              </div>
              <div

                onClick={() => {
                  if (checkUser(userState)) {
                    history.push('/FavoriteHistory');
                  };
                }
                }
                className={`d-flex-center flex-column ${pathname.includes('FavoriteHistory') ? ' active' : ''}`}
              >
                {
                  pathname.includes('FavoriteHistory') ? <img
                    src={StaticImage(
                      '/images/bottomnavigation/heart-circle-duotone-gray.svg'
                    )}
                    alt='home'
                  /> :
                    <img
                      src={StaticImage(
                        '/images/bottomnavigation/heart-circle-duotone-gray.svg'
                      )}
                      alt='home'
                    />
                }

                <label>ติดตาม</label>
              </div>
              <div
                onClick={() => {
                  if (checkUser(userState)) {
                    history.push('/NotificationHistory')
                  };
                }
                }
                className='d-flex-center flex-column'
              >
                <img
                  src={StaticImage('/images/bottomnavigation/bell-duotone-gray.svg')}
                  alt='home'
                  className={
                    pathname.includes('NotificationHistory') ? 'active' : ''
                  }
                />
                <label>แจ้งเตือน</label>
              </div>
            </div>
          )} */}
        </>
      }
	  {visible2 && (visible == false) &&
        <Modal
          title={
            'ประกาศแจ้งเตือน'
          }
          closable={false}
          style={{ zIndex: 9999, width: 'auto' }}
          maskClosable={false}
          cancelText={'ปิด'}
          width={800}
          okButtonProps={{ hidden: true }}
          visible={visible2}
          onCancel={() => {
            setVisible2(false)
          }}
        >
          <div >
            <Image preview={true} height={isMobile ? 350 : 400} src={StaticImage("/images/Cover.png")} />
          </div>
        </Modal>
      }
      {!isMobile && <>
        <div className='w-full py-5 bg-blue-50 flex items-center justify-center gap-x-10'>
          <div className='flex items-center'>
            <img alt="ocs-banner" className='h-10 mr-2' src={StaticImage('/images/phase2icon/ocs/layer-7@2x.png')} />
            <div className='flex flex-column'>
              <span className='font-bold'>สำนักงานคณะกรรมการกฤษฎีกา</span>
              <span className='font-bold'>Better Regulation for Better Life </span>
            </div>
          </div>
          <img alt="dga-banner" className='h-10 mr-2' src={StaticImage('/images/phase2icon/dga/logodga.png')} />
        </div>
        <div className='w-full flex-column h-20 bg-blue gap-y-2 flex items-center justify-center'>
          <span className='text-white text-xl'>ติดต่อสอบถาม</span>
          <div className='flex gap-x-4 text-white'>
            <span>โทรศัพท์ : (+66) 2612 6060</span>
            <span>โทรสาร : (+66) 2612 6011-12</span>
            <span>อีเมล : contact@dga.or.th</span>
          </div>
        </div>
        <div className='w-full h-12 bg-blue-50 flex items-center justify-center'>
          <span className='text-blue'>สงวนลิขสิทธิ์ พ.ศ. 2564 ตามพระราชบัญญัติลิขสิทธิ์ 2537 สำนักงานพัฒนารัฐบาลดิจิทัล (องค์การมหาชน) (สพร.)</span>
        </div></>}
      <div onClick={() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }} className="scroll-to-top fixed">
        <div>
          <ArrowUpward
            style={{
              color: '#0c4da2',
              fontSize: 28,
              minWidth: 30,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Main;

